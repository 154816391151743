import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0019 from '../../../components/molecules/columnDetailInner0019'
import ColumnRelationCards0019 from '../../../components/molecules/columnRelationCards0019'
import UsefulFunctionLayout013 from '../../../components/molecules/usefulFunctionLayout013'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0019 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title:
        'スーパーフレックス制度とは？自由度の高い働き方、メリットデメリットを解説します。',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="自由度の高い新しい働き方「スーパーフレックス制度」とは？"
        description="スーパーフレックスタイム制度とは、コアタイムをなくしたフレックスタイム制度です。通常のフレックスタイム制度より時間の融通が利くメリットがあります。"
        ogUrl="https://kintaicloud.jp/column/details/C0019/"
        ogType="article"
        ogTitle="自由度の高い新しい働き方「スーパーフレックス制度」とは？"
        ogDescription="スーパーフレックスタイム制度とは、コアタイムをなくしたフレックスタイム制度です。通常のフレックスタイム制度より時間の融通が利くメリットがあります。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0019.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0019 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout013 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards0019 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0019

export const pageQuery = graphql`
  query C0019 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
