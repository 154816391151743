import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction001 from './Introduction001'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0019 = () => (
  <Wrapper>
    <ColumnH1 label="自由度の高い新しい働き方「スーパーフレックス制度」とは？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        働き方改革の施行以降、通常の固定労働時間制だけでなく、多様な働き方が注目されています。多様な働き方の目的の一つとして労働者の職業生活と家庭生活との両立に資する働き方の模索があり、その具体策としてフレックスタイム制度が挙げられます。また、フレックスタイム制度の中でもスーパーフレックスタイム制度と言い、
        <span>コアタイムがないフレックスタイム制度</span>があります。
        <br />
        今回はスーパーフレックスタイム制度について解説します。
      </p>
      <img src="/images/column/details/c0019.jpg" alt="キンクラコラム画像19" />
      <h1>フレックスタイム制度とは</h1>
      <p>
        端的には労働者に<span>始業および終業の時刻の決定を委ねる</span>
        ことです。フレックスタイム制度を導入することで、子育てや介護をしながら働く方にとっては時間の融通が利きやすくなり、やむを得ず退職するといった判断をせずに働き続けられるメリットがあります。注意点としては、フレックスタイム制度を導入したとしても深夜割増の適用はあることから、遅い時刻に出社し、
        <span>深夜帯に働く場合には深夜割増賃金の支払いが義務</span>となります。
      </p>
      <h1>コアタイムとは</h1>
      <p>
        <span>労働者が働かなければならない時間</span>
        のことです。例えば、11時から14時の間が顧客からの問い合わせが集中する会社の場合、その時間帯をコアタイムとして設定することで、休日や休暇の日を除き、必ず働かなければならない時間として定めることが可能です。
      </p>
      <h1>スーパーフレックスタイム制度とは</h1>
      <p>
        <span>コアタイムをなくしたフレックスタイム制度</span>
        です。コアタイムがないということは、完全に労働者に働く時間を委ねることを意味し、労働者目線では、通常のフレックスタイム制度よりも更に時間の融通が利くというメリットがあります。
        <br />
        反対に会社目線では顧客からの問い合わせが集中する時間であっても必ず一定数以上の労働者が働いているという保証がないため、一定のリスクはあると言えます。
      </p>
      <h1>スーパーフレックスタイム制度のメリット</h1>
      <p>
        フレックスタイム制度と言っても長時間のコアタイムを設定してしまうと実質的に通常の固定労働時間制とほぼ変わらないというケースもあります。しかし、スーパーフレックスタイム制度の場合、そもそもコアタイムが存在しないことから、労働者目線では
        <span>極めて働き方の自由度が高い</span>
        状態となり、子供が保育園等に通園し始めた労働者や、要介護状態の家族と同居する労働者であれば、仕事を保育や介護と両立することが可能となります。
        <br />
        <br />
        次に、通常の固定労働時間制の場合、終業時刻が定められていることから、仕事が早く終わってもその時間まで在社していなければ、仮に早めに退社する場合、時間単位の有給休暇を取得するような場合を除き、給与計算上は欠勤控除の対象となります。
        <br />
        しかし、スーパーフレックスタイム制度の場合、自身で仕事が早く終わり退社する日、早く退社した日の分を挽回する日を設定することが可能となり、
        <span>生産的に働くことが可能</span>となります。
        <br />
        <br />
        また、会社としても、終業時刻前に労働者が退社すること自体イレギュラーでなくなることから、いわゆるお付き合い残業が減り、
        <span>長時間労働の防止</span>にも寄与します。
      </p>
      <Introduction001 />
      <h1>スーパーフレックスタイム制度のデメリット</h1>
      <p>
        コアタイムがないため、<span>特定の時間帯に人手不足に陥るリスク</span>
        があります。コアタイムがないということは、労働者には就労義務がないため、突発的に会議を開催しようにもメンバーが揃っていない可能性もあります。そこで、会議が開催できなかったとしても居合わせなかった労働者に懲戒処分を科すことはできません。
        <br />
        <br />
        次に<span>コミュニケーション不足</span>
        が挙げられます。これは、通常の固定労働時間制やコアタイムのあるフレックスタイム制度であれば、休日や休暇の日を除き、顔を合わせる機会は自然と多くなりますが、スーパーフレックスタイム制度の場合、余程の偶然が重ならない限り、顔を合わせる頻度が増えるということは想定できません。
        <br />
        そこで、コミュニケーション不足によるミスが起ることや、申し送り事項の共有がされておらず、インシデントの発生などが想定されます。
        <br />
        <br />
        また、<span>有給休暇の取得率の減少</span>
        が挙げられます。スーパーフレックスタイム制度の場合、通常のフレックスタイム制度よりも働き方の自由度が高く、あえて有給休暇を申請しなくても、出社時間の調整で用事を済ませることができます。
        <br />
        2019年4月1日より施行された年10日以上有給休暇が付与された労働者に対して、付与された日から1年間に5日の消化が義務付けられていますが、5日の消化が未達成となる可能性もあります。
        <br />
        尚、未達成の場合、1人に付き30万円以下の罰金が法律上規定されており、黙認できない状況となっています。
      </p>
      <h1 className="read">最後に</h1>
      <p>
        フレックスタイム制度を採用せずにいきなりスーパーフレックスタイム制度を導入するとなると、会社目線では環境の変化が大きすぎて持続的な制度の運用が難しいとの声があります。
        <br />
        <br />
        まずはフレックスタイム制度を導入し、そこで出た課題を解決し、タイミングを見てスーパーフレックスタイム制度を導入するという判断が一般的になりつつあります。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0019
